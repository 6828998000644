<template>
  <div class="SafetyDot">
    <SafetyMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click.stop="onShowNewDot"
        >新建</el-button
      >
    </div>
    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="dotList" style="width: 100%">
        <el-table-column
          prop="dotId"
          width="100"
          label="编号"
        ></el-table-column>
        <el-table-column prop="dotName" label="安检点名称"></el-table-column>
        <el-table-column label="二维码">
          <template slot-scope="scope">
            <a @click="getQrCode(scope.row)">查看二维码</a>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50, 100]"
        :total="totals"
        @current-change="getDotList"
        @size-change="getDotList"
      >
      </el-pagination>
    </div>
    <!-- 二维码 -->
    <div class="qrCodeWrap" v-show="showQrCode" @click="showQrCode = false">
      <h2>{{ qrCodeName }}</h2>
      <div id="qrcode"></div>
      <el-button @click="downLoad">立即下载</el-button>
    </div>
    <!-- 新建安检点 -->
    <el-dialog
      title="新建安检点"
      :visible.sync="showNewDot"
      style="width: 800px; margin: 0 auto"
    >
      <el-form>
        <el-form-item>
          <el-input
            v-model="newDotName"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showNewDot = false">取消</el-button>
        <el-button size="small" type="primary" @click="onAdd">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.SafetyDot {
  .el-form-item {
    margin-bottom: 0;
  }
  .fileIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .fileName {
    font-size: 14px;
    line-height: 16px;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }

  .qrCodeWrap {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #qrcode {
      margin: 30px;
    }
    h2 {
      font-size: 40px;
      color: #fff;
    }
  }
}
</style>

<script>
import SafetyMenu from "@/components/SafetyMenu.vue";
import QRCode from "qrcodejs2";

export default {
  name: "ProjectFile",
  components: {
    SafetyMenu,
  },
  data() {
    return {
      loading: null,
      projectList: [],
      currentProject: null,

      dotList: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      currentRow: null,
      qrCode: "",
      qrCodeName: "",
      showQrCode: false,

      showNewDot: false,
      newDotName: "",
    };
  },
  computed: {},
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (newVal, oldVal) {
      this.getDotList(); // 获取巡检点位列表
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取巡检点位列表
    getDotList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyDot/dotList",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.dotList = res.data.dotList;
          that.totals = res.data.totals;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 取得二维码
    getQrCode: function (row) {
      var that = this;
      this.qrCode = row.dotCode;
      this.qrCodeName = row.dotName;
      document.getElementById("qrcode").innerHTML = "";
      var qrcode = new QRCode("qrcode", {
        width: 300,
        height: 300,
        text: that.qrCode,
        colorDark: "#000",
        colorLight: "#fff",
      });
      this.showQrCode = true;
    },
    // 下载二维码
    downLoad: function () {
      let myCanvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/png");
      a.download = this.qrCodeName;
      a.click();
      this.$message({
        message: "正在进行下载保存",
        type: "success",
      });
    },
    // 删除点位
    onDel: function (row) {
      var that = this;
      var data = {
        dotId: row.dotId,
      };
      this.$confirm("此操作将永久删除该点位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/safetyDot/del",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getDotList();
              that.loading.close();
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 添加点位
    onAdd: function () {
      var that = this;
      var data = {
        dotId: "",
        projectId: this.currentProject,
        dotName: this.newDotName,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyDot/saveDot",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.$message({
            type: "success",
            message: "添加成功!",
          });
          that.getDotList();
          that.showNewDot = false;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开新建点位
    onShowNewDot: function () {
      this.showNewDot = true;
      this.newDotName = "";
    },
  },
};
</script>